// Colores principales
$primary-color: #30334B;
$primary-light: #2f3349;
$primary-dark: #202232;

$secondary-color: hsla(232, 90%, 59%, 1);
$secondary-light: hsla(232, 90%, 70%, 1);
$secondary-dark: hsla(232, 90%, 40%, 1);

// Estados
$success-color: hsla(141, 71%, 48%, 1);
$success-light: hsla(141, 71%, 65%, 1);
$success-dark: hsla(141, 71%, 35%, 1);

$warning-color: hsla(45, 100%, 51%, 1);
$warning-light: hsla(45, 100%, 65%, 1);
$warning-dark: hsla(45, 100%, 40%, 1);

$error-color: hsla(348, 86%, 51%, 1);
$error-light: hsla(348, 86%, 70%, 1);
$error-dark: hsla(348, 86%, 40%, 1);

$info-color: hsla(207, 90%, 54%, 1);
$info-light: hsla(207, 90%, 65%, 1);
$info-dark: hsla(207, 90%, 40%, 1);

// Gráficos
$chart-color-1: hsla(200, 90%, 50%, 1);
$chart-color-2: hsla(340, 82%, 52%, 1);
$chart-color-3: hsla(56, 100%, 58%, 1);
$chart-color-4: hsla(120, 57%, 40%, 1);
$chart-color-5: hsla(30, 100%, 50%, 1);
$chart-color-6: hsla(264, 64%, 52%, 1);
$chart-color-7: hsla(198, 78%, 35%, 1);

// Neutros
$gray-light: #f0f0f0;
$gray-medium: #cccccc;
$gray-dark: #555555;
$gray-black: #222222;
$white-neutral: #FFF;
$black-medium:#333333;
$black-dark: #000;
// Botón Primario
.btn-primary {
    background-color: $primary-color;
    color: $white-neutral;
    border: 1px solid $primary-dark;
    border-radius: 50px;
    width: 100%;

    &:hover {
        background-color: $primary-light;
        border-color: $primary-color;
    }

    &:active {
        background-color: $primary-dark;
        border-color: $primary-dark;
    }

    &:disabled {
        background-color: $gray-medium;
        border-color: $gray-light;
        color: $gray-dark;
    }
}

// Botón Secundario
.btn-secondary {
    border-radius: 50px;
    background-color: $secondary-color;
    color: $white-neutral;
    border: 1px solid $secondary-dark;

    &:hover {
        background-color: $secondary-light;
        border-color: $secondary-color;
    }

    &:active {
        background-color: $secondary-dark;
        border-color: $secondary-dark;
    }

    &:disabled {
        background-color: $gray-medium;
        border-color: $gray-light;
        color: $gray-dark;
    }
}

// Botón Éxito (Success)
.btn-success {
    background-color: $success-color;
    color: $white-neutral;
    border: 1px solid $success-dark;

    &:hover {
        background-color: $success-light;
        border-color: $success-color;
    }

    &:active {
        background-color: $success-dark;
        border-color: $success-dark;
    }

    &:disabled {
        background-color: $gray-medium;
        border-color: $gray-light;
        color: $gray-dark;
    }
}

// Botón Advertencia (Warning)
.btn-warning {
    background-color: $warning-color;
    color: $black-dark;
    border: 1px solid $warning-dark;

    &:hover {
        background-color: $warning-light;
        border-color: $warning-color;
    }

    &:active {
        background-color: $warning-dark;
        border-color: $warning-dark;
    }

    &:disabled {
        background-color: $gray-medium;
        border-color: $gray-light;
        color: $gray-dark;
    }
}

// Botón Error (Danger)
.btn-danger {
    background-color: $error-color;
    color: $white-neutral;
    border: 1px solid $error-dark;
    border-radius: 50px;

    &:hover {
        background-color: $error-light;
        border-color: $error-color;
    }

    &:active {
        background-color: $error-dark;
        border-color: $error-dark;
    }

    &:disabled {
        background-color: $gray-medium;
        border-color: $gray-light;
        color: $gray-dark;
    }
}

// Botón Información (Info)
.btn-info {
    background-color: $info-color;
    color: $white-neutral;
    border: 1px solid $info-dark;

    &:hover {
        background-color: $info-light;
        border-color: $info-color;
    }

    &:active {
        background-color: $info-dark;
        border-color: $info-dark;
    }

    &:disabled {
        background-color: $gray-medium;
        border-color: $gray-light;
        color: $gray-dark;
    }
}